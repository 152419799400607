import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import logo from "./../images/logo.png" // Tell Webpack this JS file uses this image

const Header = ({ siteTitle }) => (
  <header>
    <h1>
      <Link to="/">
        <img src={logo} className="logo" alt={siteTitle} />
      </Link>
    </h1>
    <nav>
      <ul className="main-nav">
        <li>
          <Link className="nav-item" to="/#unser-angebot">
            Unser Angebot
          </Link>
        </li>
        <li>
          <Link className="nav-item" to="/#oeffnungszeiten">
            Öffnungszeiten
          </Link>
        </li>
        <li>
          <Link className="nav-item" to="/#anfahrt-und-kontakt">
            Anfahrt &amp; Kontakt
          </Link>
        </li>
      </ul>
    </nav>
    <div id="stage">
      <picture>
        <img id="stage-image" src="/images/blumenwiese.jpg" alt="" />
      </picture>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
