/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import ConsentToCookiesContext from '../context/ConsentToCookiesContext'
import Header from "./header"
import Footer from "./footer"
import CookieConsent from 'react-cookie-consent';
import "./layout.sass"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <ConsentToCookiesContext.Consumer>
        {cookieConsent => (
            <div className={`page-wrapper`}>
                <Header siteTitle={data.site.siteMetadata.title} />
                <main>{children}</main>
                <Footer siteTitle={data.site.siteMetadata.title} />
                <CookieConsent
                    location="bottom"
                    buttonText="Zustimmen und weiter"
                    buttonClasses="button"
                    enableDeclineButton={true}
                    declineButtonText="Bitte nicht"
                    cookieName="gatsby-gdpr-google-analytics"
                    disableStyles={true}
                    onAccept={() => cookieConsent.accept()}
                    onDecline={() => cookieConsent.decline()}>
                          <p>Ihre Zufriedenheit ist unser Ziel, deshalb verwenden wir Cookies und andere Daten. Mit diesen ermöglichen wir, dass unsere Webseite zuverlässig und sicher läuft. Auch können wir mit diesen Daten unser Angebot an Sie verbessern.</p>
                          <p>Mit Klick auf „Zustimmen und weiter“ erklären Sie sich mit der Verwendung dieser Daten einverstanden.</p>
                </CookieConsent>
            </div>
        )}
      </ConsentToCookiesContext.Consumer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
